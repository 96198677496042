import React from 'react'
import { Link } from 'react-router-dom'

function LinkNavigation({ prev_link="", prev_label="", next_link="", next_label="", prev = true, next = true }) {
    return (
        <dl className="mt-12 flex border-t border-slate-200 pt-6 dark:border-slate-800">
            <div>
                {prev ?
                    <div>
                        <dt className="font-display text-sm font-medium text-slate-900 dark:text-white">
                            Previous
                        </dt>
                        <dd className="mt-1">
                            <Link
                                className="text-base font-semibold text-slate-500 hover:text-slate-600 dark:text-slate-400 dark:hover:text-slate-300"
                                href={prev_link}
                            >
                                <span aria-hidden="true">←</span> {prev_label}
                            </Link>
                        </dd>
                    </div> : <div></div>}
            </div>
            <div className="ml-auto text-right">
                {next ?
                    <div>
                        <dt className="font-display text-sm font-medium text-slate-900 dark:text-white">
                            Next
                        </dt>
                        <dd className="mt-1">
                            <Link
                                className="text-base font-semibold text-slate-500 hover:text-slate-600 dark:text-slate-400 dark:hover:text-slate-300"
                                to={next_link}
                            >
                                {next_label}
                                <span aria-hidden="true">→</span>
                            </Link>
                        </dd>
                    </div> : <div></div>}
            </div>

        </dl>
    )
}

export default LinkNavigation