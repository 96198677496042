import React from 'react'
import { Link } from 'react-router-dom'
import Topnav from './nav/Topnav'
import image1 from '../image/shirt-blue.png';
import image2 from '../image/shirt-cyan.png';
import image3 from '../image/shirt-gray.png';
import image4 from '../image/shirt-orange.png';
import image5 from '../image/shirt-pink.png';
import image6 from '../image/f-shirt-blue.png';
import image7 from '../image/f-shirt-cyan.png';
import image8 from '../image/f-shirt-green.png';
import cake1 from '../image/1.jpg';
import cake2 from '../image/2.jpg';
import cake3 from '../image/3.jpg';
import cake4 from '../image/4.jpg';
import cake5 from '../image/5.jpg';
import cake6 from '../image/6.jpg';
import cake7 from '../image/7.jpg';
import fruit1 from '../image/6/1.jpg';
import fruit2 from '../image/6/2.jpg';
import fruit3 from '../image/6/3.jpg';
import fruit8 from '../image/6/8.jpg';
import fruit5 from '../image/6/5.jpg';
import shoe1 from '../image/5/1.png';
import shoe2 from '../image/5/2.png';
import shoe3 from '../image/5/3.png';
import shoe4 from '../image/5/4.png';
import shoe5 from '../image/5/5.png';

function Home() {
  const products = [
    { image: image1 },
    { image: image2 },
    { image: image3 },
    { image: image4 },
    { image: image5 }
  ]

  const categories = [
    { image: cake1 },
    { image: cake2 },
    { image: cake3 },
    { image: cake4 },
    { image: cake5 }
  ]

  const fruits = [
    { image: fruit1 },
    { image: fruit2 },
    { image: fruit3 },
    { image: fruit8 },
    { image: fruit5 }
  ]

  const shoes = [
    { image: shoe1 },
    { image: shoe2 },
    { image: shoe3 },
    { image: shoe4 },
    { image: shoe5 }
  ]
  return (
    <div>
      <Topnav />
      <div className="px-5 mx-auto dark:bg-black">
        <div className="py-12">
          <h1 className="text-center text-lg md:text-xl dark:text-[#FFE332]">
            Unlock the power of FakestoreAPI – a leading RESTful API for mock e-commerce data. Test and develop with ease using realistic product information, from titles to prices, categories, and more. No authentication required, and with pagination support, navigate effortlessly. Enhance your coding skills, create captivating demos, and prototype with confidence. Access comprehensive documentation and provide feedback to creators. Accelerate your projects with FakestoreAPI today.
          </h1>
          <div className="flex justify-center pt-5">
            <Link to="/docs">
              <div className='px-4 py-2 rounded-lg border-2 border-black hover:bg-black hover:text-white dark:border-[#FFE332] dark:bg-transparent dark:text-[#FFE332] dark:hover:bg-[#FFE332] dark:hover:text-black'>
                View Docs
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="px-5 mx-auto dark:bg-black">
        <div className="flex flex-col text-center w-full mb-4">
          <h1 className="text-xl font-medium tracking-widest text-gray-900 dark:text-[#FFE332] mt-4 my-font">Products</h1>
        </div>
        <div className="flex flex-wrap">
          {categories.map((e) => (
            <div className="p-4 md:w-1/5">
              <div className="h-full flex flex-col items-center text-center rounded-lg dark:bg-opacity-40">
                <img className="rounded-lg w-full h-full object-cover object-center mb-4" src={e.image} />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="px-5 mx-auto dark:bg-black">
        <div className="flex flex-col text-center w-full mb-4">
          <h1 className="text-xl font-medium tracking-widest text-gray-900 dark:text-[#FFE332] mt-4 my-font">Fruits</h1>
        </div>
        <div className="flex flex-wrap">
          {fruits.map((e) => (
            <div className="p-4 md:w-1/5">
              <div className="h-full flex flex-col items-center text-center rounded-lg dark:bg-opacity-40">
                <img className="rounded-lg w-full h-full object-cover object-center mb-4" src={e.image} />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="my-6 px-8 md:px-20">
        <h2 className="text-xl md:text-3xl font-black pb-3">Frequently Asked Questions(FAQ)</h2>
        <ul className="list-decimal">
          <li className="pb-4">
            <strong>Q: What is the Fakestore API?</strong><br />
            A: The Fakestore API is a free RESTful API that provides mock e-commerce data for testing and development purposes.
          </li>
          <li className="pb-4">
            <strong>Q: How can I use the Fakestore API?</strong><br />
            A: You can use the Fakestore API by making HTTP requests to the provided endpoints to retrieve mock product and user data.
          </li>
          <li className="pb-4">
            <strong>Q: What kind of data does the Fakestore API offer?</strong><br />
            A: The Fakestore API offers data related to products, including information such as title, price, description, category, and image URL.
          </li>
          <li className="pb-4">
            <strong>Q: Is the data from the Fakestore API real or fake?</strong><br />
            A: The data from the Fakestore API is mock data and is not associated with real e-commerce stores or products.
          </li>
          <li className="pb-4">
            <strong>Q: How can I access the Fakestore API?</strong><br />
            A: You can access the Fakestore API by making HTTP GET requests to the relevant endpoints, using tools like cURL or programming languages like JavaScript.
          </li>
          <li className="pb-4">
            <strong>Q: Are there any limitations or restrictions on using the Fakestore API?</strong><br />
            A: The Fakestore API is free to use, but there may be rate limits in place to prevent abuse and ensure fair usage.
          </li>
          <li className="pb-4">
            <strong>Q: Can I use the Fakestore API in my production application?</strong><br />
            A: The Fakestore API is primarily intended for testing and development purposes, so it is not recommended for use in production applications.
          </li>
          <li className="pb-4">
            <strong>Q: Is the Fakestore API suitable for learning and practicing API integration?</strong><br />
            A: Absolutely! The Fakestore API provides a great opportunity to learn and practice integrating with an API and working with mock data.
          </li>
          <li className="pb-4">
            <strong>Q: How often is the data in the Fakestore API updated?</strong><br />
            A: The data in the Fakestore API is static and does not change over time. It provides a consistent set of mock data for testing purposes.
          </li>
          <li className="pb-4">
            <strong>Q: Can I contribute to the Fakestore API?</strong><br />
            A: The Fakestore API is not currently open for contributions, as it is maintained by its creators. However, you can provide feedback and suggestions to them.
          </li>
          <li className="pb-4">
            <strong>Q: Is the Fakestore API suitable for building demo applications or prototypes?</strong><br />
            A: Yes, the Fakestore API is a perfect choice for building demo applications or prototypes, allowing you to showcase e-commerce functionality.
          </li>
          <li className="pb-4">
            <strong>Q: Can I retrieve specific product categories from the Fakestore API?</strong><br />
            A: Yes, the Fakestore API supports filtering products by category. You can specify the category as a query parameter in your API request.
          </li>
          <li className="pb-4">
            <strong>Q: Is authentication required to access the Fakestore API?</strong><br />
            A: No, the Fakestore API does not require authentication. You can freely access the available endpoints without any authentication credentials.
          </li>
          <li className="pb-4">
            <strong>Q: Can I use the product images from the Fakestore API in my application?</strong><br />
            A: Yes, you can use the product images provided by the Fakestore API in your application. However, it is always recommended to host your own images for production applications.
          </li>
          <li className="pb-4">
            <strong>Q: Does the Fakestore API provide pricing information in different currencies?</strong><br />
            A: No, the Fakestore API provides prices in a single currency (usually USD). If you need multi-currency support, you would need to implement that logic in your application.
          </li>
          <li className="pb-4">
            <strong>Q: Can I get customer reviews and ratings from the Fakestore API?</strong><br />
            A: The Fakestore API does not provide customer reviews and ratings for products. It focuses primarily on basic product information.
          </li>
          <li className="pb-4">
            <strong>Q: Does the Fakestore API support pagination for retrieving large amounts of data?</strong><br />
            A: Yes, the Fakestore API supports pagination. You can use query parameters like "limit" and "page" to control the number of results and navigate through the data.
          </li>
          <li className="pb-4">
            <strong>Q: How can I report issues or provide feedback about the Fakestore API?</strong><br />
            A: If you encounter any issues or have feedback about the Fakestore API, you can contact the creators through their official channels, such as their website or GitHub repository.
          </li>
          <li className="pb-4">
            <strong>Q: Is the Fakestore API documentation available?</strong><br />
            A: Yes, the Fakestore API provides documentation that describes the available endpoints, query parameters, and response formats. You can refer to the documentation for implementation details.
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Home