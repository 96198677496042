import React from "react"
import Sidebar from '../components/nav/Sidebar';

const Docs = () => {

    return (
        <Sidebar>
            <div className="px-5 mx-auto">
                <h1 className="text-2xl py-2 my-font text-gray-600">
                    Introduction
                </h1>
                <h2 className="text-justify py-2 tracking-widest">
                    Looking for a reliable RESTful API to simulate a real online store for testing and development purposes? Look no further than Fake Store API. Our user-friendly interface and comprehensive suite of functionalities provide a hassle-free solution for eCommerce developers looking to optimize their development process. With Fake Store API, you can confidently test your applications and ensure seamless performance without the need for an actual online store. Try it out today and take your eCommerce development to the next level!
                </h2>

                <h2 class="mb-2 text-lg font-semibold text-gray-600 py-3">List of API</h2>
                <ul class="max-w-md space-y-1 text-gray-500 list-inside tracking-widest">
                    <li class="flex items-center">
                        <svg class="w-4 h-4 mr-1.5 text-green-500 flex-shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
                        Categories
                    </li>
                    <li class="flex items-center">
                        <svg class="w-4 h-4 mr-1.5 text-green-500 flex-shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
                        Products
                    </li>
                    <li class="flex items-center">
                        <svg class="w-4 h-4 mr-1.5 text-green-500 flex-shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
                        Users
                    </li>
                    <li class="flex items-center">
                        <svg class="w-4 h-4 mr-1.5 text-green-500 flex-shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
                        Auth
                    </li>
                </ul>

            </div>
        </Sidebar>
    )
}

export default Docs