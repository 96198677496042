import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import './styles/output.css'
import './styles/style.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
      <App />
  </React.StrictMode>,
)
reportWebVitals()
