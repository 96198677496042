import Markdown from "markdown-to-jsx"
import { useEffect, useState } from "react"
import Code from "../../components/Code";
import Sidebar from '../../components/nav/Sidebar';

const PostLogin = () => {
    const [postContent, setPostContent] = useState("");

    useEffect(() => {
        import("../../markdown/login/postlogin.md")
            .then(res => {
                fetch(res.default)
                    .then(response => response.text())
                    .then(response => setPostContent(response))
                    .catch(err => console.log(err))
            })
    }, []);

    return (
        <Sidebar>
            <Markdown options={{
                overrides: {
                    Code: {
                        component: Code
                    }
                }
            }}>
                {postContent}
            </Markdown>
        </Sidebar>
    )
}

export default PostLogin