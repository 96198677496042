import React from 'react'
import Home from './components/Home'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import FetchProducts from './docs/product/FetchProducts'
import GetProduct from './docs/product/GetProduct'
import FetchCategory from './docs/category/FetchCategory'
import GetCategory from './docs/category/GetCategory'
import FetchUsers from './docs/user/FetchUsers'
import GetUser from './docs/user/GetUser'
import PostLogin from './docs/login/PostLogin'
import Docs from './docs/Docs'

const App = () => {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} exact />
          <Route path="/docs" element={<Docs />} exact />
          <Route path="/docs/products" element={<FetchProducts />} exact />
          <Route path="/docs/get-product" element={<GetProduct />} exact />
          <Route path="/docs/categories" element={<FetchCategory />} exact />
          <Route path="/docs/get-category" element={<GetCategory />} exact />
          <Route path="/docs/users" element={<FetchUsers />} exact />
          <Route path="/docs/get-user" element={<GetUser />} exact />
          <Route path="/docs/products" element={<FetchProducts />} exact />
          <Route path="/docs/login" element={<PostLogin />} exact />
        </Routes>
      </Router>
    </div>
  )
}
export default App
